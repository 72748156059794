import { setupMasonry } from "./masonry";
import { setupFilter } from "./filtering";
import "./error";

// projects masonry
let projectContainer = ".project__gallery";
let projectItem = ".project__masonry";
let projectSizer = ".project__sizer";
let projectSpacer = ".project__spacer";
if (projectItem) {
  // apply masonry
  setupMasonry(projectContainer, projectItem, projectSizer, projectSpacer);
}

let libraryContainer = ".library__bookshelf";
let libraryItem = ".library-item";
let libraryFilter = ".library__filter";
if (libraryItem) {
  setupFilter(libraryContainer, libraryItem, libraryFilter);
}
