import $ from "jquery";
import Isotope from "isotope-layout";

export { setupFilter };

function setupFilter(container, item, filter) {
  if ($(container).length) {
    $(filter).on("click", function () {
      let filterName = $(this).attr("data-media");
      let filteredItems = document.querySelectorAll("." + filterName);
      let items = document.querySelectorAll(item);
      $(this).addClass("active");
      $(this).siblings().removeClass("active");
      items.forEach((item) => {
        item.hidden = true;
      });
      filteredItems.forEach((filtereditem) => {
        filtereditem.hidden = false;
      });
    });
  }
}

// function setupFilter(container, item, filter) {
//   if ($(container).length) {
//     $(filter).on("click", function () {
//       let filterName = $(this).attr("data-media");
//       console.log("." + filterName);
//       $(item).hide();
//       $("." + filterName).show();
//     });
//   }
// }

// function setupFilter(container, item, sizer, spacer) {
//   if ($(container).length) {
//     var newFilter = new Isotope(container, {
//       itemSelector: item,
//     });
//   }

//   $(".filter-button-group").on("click", "button", function () {
//     var filterValue = $(this).attr("data-filter");
//     $grid.isotope({ filter: filterValue });
//   });

//   return newFilter;
// }
